import { auth, database } from "../database/config";
import List from "../hsCodeList/AllHsCode.json";
import AllPorts from "../hsCodeList/AllPortsAirports.json";

export default async function HsCodeList() {
  return new Promise(async (resolve, reject) => {
    let products = [];

    List.forEach((item) => {
      let data = {
        hsCode: item.hsCode,
        hsName: item.hsName,
      };
      products.push(data);
    });

    if (products.length > 0) {
      resolve(products);
    } else {
      reject("empty");
    }
  });
}

export async function PortsList() {
  try {
    const products = AllPorts.map((item) => ({
      portName: item.name,
    }));

    if (products.length > 0) {
      return products;
    } else {
      throw new Error("No ports available");
    }
  } catch (error) {
    console.error("Error in PortsList:", error.message);
    throw error;
  }
}

//Versão do banco de dados
// export default async function HsCodeList() {
//   return new Promise(async (resolve, reject) => {
//     let products = [];
//     //await database.ref("ProductORDR").once("value", (snapshot) => {
//     //await database.ref("AllProduct").once("value", (snapshot) => {
//     await database.ref("HsCodeList6").once("value", (snapshot) => {
//       snapshot.forEach((item) => {
//         let data = {
//           key: item.key,
//           hsCode: item.val().hsCode,
//           hsName: item.val().hsName,
//         };
//         products.push(data);
//       });
//     });
//     if (products.length > 0) {
//       console.log("Request List Hs Code")
//       console.log(new Date())
//       resolve(products);
//     } else {
//       reject("empty");
//     }
//   });
// }

// mudar para AllProduct no lugar de Product quando for normalizado
export async function AllHsCodeList() {
  return new Promise(async (resolve, reject) => {
    let products = [];
    //await database.ref("HsCodeList6").once("value", (snapshot) => {
    await database.ref("Product").once("value", (snapshot) => {
      snapshot.forEach((item) => {
        let data = {
          key: item.key,
          hsCode: item.val().hsCode,
          hsName: item.val().hsName,
        };
        products.push(data);
      });
    });
    if (products.length > 0) {
      resolve(products);
    } else {
      reject("empty");
    }
  });
}

export async function addProductData(hsCode, hsName) {
  try {
    const productRef = database.ref("Product").push();
    const productKey = productRef.key;

    await productRef.update({
      hsCode: `${hsCode}`,
      hsName: hsName,
    });

    return productKey;
  } catch (error) {
    console.error("Erro ao adicionar dados do produto:", error);
    throw error;
  }
}

export async function favoritedHs(hsCode, hsName) {
  var user = auth.currentUser;

  await database
    .ref("Users")
    .child(user.uid)
    .child("hsFavorited")
    .child(hsCode)
    .set({
      hsCode,
      hsName,
    });
}

export async function excludeFavoritedHs(hsCode) {
  var user = auth.currentUser;

  await database
    .ref("Users")
    .child(user.uid)
    .child("hsFavorited")
    .child(hsCode)
    .remove();
}

export async function getFavoritedHs() {
  return new Promise(async (resolve, reject) => {
    var user = auth.currentUser;
    let list = [];

    await database
      .ref("Users")
      .child(user.uid)
      .child("hsFavorited")
      .once("value", (snapshot) => {
        snapshot.forEach((item) => {
          let data = {
            hsCode: item.val().hsCode,
            hsName: item.val().hsName,
          };

          list.push(data);
        });
      });

    if (list.length > 0) {
      resolve(list);
    } else {
      reject("empty");
    }
  });
}
