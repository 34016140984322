import React, { useEffect, useRef, useState } from "react";
import {
  AddNewCard,
  CardHsList,
  CardSelected,
  ColumnContainer,
  ContainerModalHs,
  ImgClose,
  ImgDefault,
  ModalPainel,
  RowContainer,
  TagHsCode,
  TextDefault,
} from "../../../../assets/styles";
import { Modal } from "@mui/material";
import SearchInput from "../../../home/components/SearchInput";
import Capitalize from "../../../../utils/capitalize";

import { FixedSizeList as List } from "react-window";
import { PortsList } from "../../../../hooks/HsCodeList";

export default function PortAirportSelected({ ...props }) {
  const [isModal, setIsModal] = useState(false);
  const [search, setSearch] = useState("");
  const [portSelected, setPortSelected] = useState([]);
  const [portOfLadingOriginal, setPortOfLadingOriginal] = useState([]);
  const [portOfLading, setPortLading] = useState([]);

  const ExcludeHs = (key) => {
    const updatedPorts = portSelected.filter((obj) => obj.portName !== key);

    setPortSelected(updatedPorts);

    props.setPortLading(updatedPorts.map((port) => port.portName));
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const addMoreHsCode = () => {
    //if (hsCodeAll.length === 0) {
    setTimeout(() => {
      PortsList().then((resp) => {
        setPortOfLadingOriginal(resp);
        setPortLading(resp);
      });
    }, 1000);
    //   }
    setIsModal(true);
  };

  useEffect(() => {
    if (search.trim() === "") {
      setPortLading(portOfLadingOriginal);
    } else {
      setPortLading(
        portOfLadingOriginal.filter((obj) =>
          obj.portName?.toLowerCase()?.includes(search.toLowerCase())
        )
      );
    }
  }, [search, portOfLadingOriginal]);

  const AddPort = (portName) => {
    if (portSelected.length < 5) {
      const updatedPorts = [...portSelected, { portName }];
      setPortSelected(updatedPorts);
      props.setPortLading(updatedPorts.map((port) => port.portName));
      setIsModal(false);
      setSearch("");
    }
  };

  function extrairNumeros(texto) {
    let numerosEncontrados = texto.match(/\d+/g);

    if (numerosEncontrados !== null) {
      return numerosEncontrados.toString();
    } else {
      return [];
    }
  }

  return (
    <>
      <Modal
        open={isModal}
        onClose={() => closeModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContainerModalHs>
          <ModalPainel>
            <RowContainer
              style={{
                width: "100%",
                justifyContent: "flex-end",
                paddingRight: 20,
              }}
            >
              <ImgClose
                onClick={closeModal}
                src={require("../../../../icons/CloseG1.png")}
                width="14px"
                height="14px"
              />
            </RowContainer>

            <RowContainer style={{ width: "97%", marginBottom: 10 }}>
              <SearchInput
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </RowContainer>

            {portOfLading.length == 0 ? (
              <RowContainer
                style={{
                  height: "70%",
                  marginLeft: 20,
                  width: "94%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div class="loaderVV">
                  <span class="loaderVV-text">loading</span>
                  <span class="loadVV"></span>
                </div>
              </RowContainer>
            ) : (
              <ColumnContainer
                style={{ overflow: "auto", height: "80%", width: "100%" }}
              >
                <TextDefault
                  color="#4b4b4b"
                  size="25px"
                  bold="800"
                  style={{ marginLeft: 8 }}
                >
                  All ports and airports
                </TextDefault>
                {portOfLading?.length > 0 ? (
                  <List
                    height={600}
                    itemCount={portOfLading.length}
                    itemSize={80}
                    width="100%"
                  >
                    {/* {({ index, style }) => (
                      <div style={style}>
                        {portOfLading[index]?.portName || "N/A"}
                      </div>
                    )} */}
                    {({ index, style }) => {
                      const item = portOfLading[index];
                      return (
                        <ColumnContainer
                          style={{ paddingLeft: 8, ...style }}
                          key={index}
                        >
                          <CardHsList>
                            <RowContainer
                              style={{
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                paddingLeft: 30,
                              }}
                              onClick={() => AddPort(item.portName)}
                            >
                              <TextDefault
                                className="lineLM2"
                                color="#4b4b4b"
                                style={{
                                  marginLeft: 30,
                                  width: "89%",
                                  paddingRight: 40,
                                }}
                              >
                                {item.portName}
                              </TextDefault>
                            </RowContainer>
                          </CardHsList>
                        </ColumnContainer>
                      );
                    }}
                  </List>
                ) : (
                  <>
                    {extrairNumeros(search).length > 0 ? (
                      <ColumnContainer style={{ paddingLeft: 8 }}>
                        <CardHsList>
                          <RowContainer
                            style={{
                              width: "100%",
                              height: "100%",
                              alignItems: "center",
                              paddingLeft: 30,
                            }}
                            // onClick={() =>
                            //   AddHsCode(
                            //     extrairNumeros(search),
                            //     "HS Code Custom"
                            //   )
                            // }
                          >
                            <TagHsCode style={{ backgroundColor: "#E53069" }}>
                              <TextDefault size="14px" color="#fff">
                                {extrairNumeros(search)}
                              </TextDefault>
                            </TagHsCode>

                            <TextDefault
                              className="lineLM2"
                              color="#4b4b4b"
                              style={{
                                marginLeft: 30,
                                width: "89%",
                                paddingRight: 40,
                              }}
                            >
                              Add this HS Code manually - HS Code Custom
                            </TextDefault>
                          </RowContainer>

                          <ImgDefault
                            src={require("../../../../icons/wrench.png")}
                            width="19px"
                            height="19px"
                          />
                        </CardHsList>
                      </ColumnContainer>
                    ) : (
                      <RowContainer
                        style={{
                          width: "96.5%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ImgDefault
                          src={require("../../../../icons/empty-box.png")}
                          width="130px"
                          height="130px"
                        />
                      </RowContainer>
                    )}
                  </>
                )}
              </ColumnContainer>
            )}
          </ModalPainel>
        </ContainerModalHs>
      </Modal>
      <ColumnContainer style={{ marginTop: 20 }}>
        <TextDefault color="#8a97aa" size="11px" style={{ marginBottom: 8 }}>
          Port of Lading*
        </TextDefault>

        {portSelected
          ? portSelected.map((item) => (
              <RowContainer
                style={{ alignItems: "center", marginBottom: 20 }}
                key={item.key}
              >
                <CardSelected>
                  <TextDefault
                    className="lineLM1"
                    size="13px"
                    style={{ width: "75%" }}
                    color="#8a97aa"
                  >
                    {Capitalize(item.portName)}
                  </TextDefault>
                  <div class="tooltip-container">
                    <span class="tooltip">{Capitalize(item.portName)}</span>
                    <ImgDefault
                      src={require("../../../../icons/document.png")}
                      width="20px"
                      height="20px"
                    />
                  </div>
                </CardSelected>
                <ImgClose
                  onClick={() => ExcludeHs(item.portName)}
                  src={require("../../../../icons/CloseG1.png")}
                  width="14px"
                  height="14px"
                  style={{ marginLeft: 10 }}
                />
              </RowContainer>
            ))
          : ""}

        {(props.listType != "un" && portSelected.length < 5) ||
        (props.listType == "un" && portSelected.length < 1) ? (
          <AddNewCard onClick={() => addMoreHsCode()}>
            <TextDefault
              color="#8a97aa"
              size="13px"
              bold="700"
              style={{ opacity: 0.5 }}
            >
              Add port{" "}
            </TextDefault>
            <ImgDefault
              src={require("../../../../icons/add.png")}
              style={{ opacity: 0.5 }}
              width="17px"
              height="17px"
            />
          </AddNewCard>
        ) : (
          ""
        )}
      </ColumnContainer>
    </>
  );
}
