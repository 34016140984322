import React, { useContext, useState } from "react";
import {
  BtnClearFilter,
  BtnSFilter,
  ColumnContainer,
  ContainerFilterBar,
  GroupInput,
  ImgDefault,
  ImgOpt,
  InputDefault,
  RowContainer,
  SelectDefault,
  TextDefault,
} from "../../../../assets/styles";
import Country from "../../../../components/Flag";
import CountryShip from "../../utils/flagShipments";
import HsCodeSelected from "../HsCodeSelected";
import { TradeDataContext } from "../../useFNP";
import { message } from "antd";
import { IndustriesMock } from "../../../../assets/mocks/industries";
import { DatePicker, Space } from "antd";
import Select from "react-select";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PortAirportSelected from "../PortsSelected";
import PortAirportUnladingSelected from "../PortsUnlading";
const { RangePicker } = DatePicker;
const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "240px",
    height: "45px",
    lineHeight: "30px",
    border: "2px solid #e9edf8",
    borderRadius: "10px",
    backgroundColor: "#f8fafc",
    color: "#4b4b4b",
    transition: "0.5s ease",
    fontWeight: 530,
    fontSize: "13px",
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e5e7eb",
    color: "#4b4b4b",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#4b4b4b",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
};

export default function FilterBar({ ...props }) {
  const [basedOn, setBasedOn] = useState("");
  const [role, setRole] = useState("");
  const [period, setPeriod] = useState("");
  const [periodUN, setPeriodUN] = useState("");
  const [show, setShow] = useState("");
  const [countryPartner, setCountryPartner] = useState("World");
  const [companyName, setCompanyName] = useState("");
  const [hsCode, setHsCode] = useState([]);
  const [test, setTest] = useState([]);
  const [hsCodeSelected, setHsCodeSelected] = useState([]);
  const [hsCodeM, setHsCodeM] = useState(false);
  const [portL, setPortL] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    switchValue,
    fetchCompaniesByName: fetchCompanies,
    fetchCompaniesByNameDB,
    requestCompanies: requestCompaniesAPI,
    RequestCompaniesDb,
    fetchShipments,
    fetchShipmentsByHs,
    companyLink,
    setCompanyLink,
    type,
    setType,
    industries,
    setIndustries,
    fetchGeoCompanies,
    fetchScout,
    size,
    setSize,
    country,
    setCountry,
    listType,
    setListType,
    states,
    setStates,
    setCountryTrade,
    countryTrade,
    toDateShip,
    setToDateShip,
    fromDateShip,
    setFromDateShip,
    keyWordsIncluded,
    setKeyWordsIncluded,
    keyWordsExcluded,
    setKeyWordsExcluded,
    productKeywords,
    setProductKeywords,
    portOfLading,
    setPortLading,
    portOfUnlading,
    setPortUnlading,
  } = useContext(TradeDataContext);

  const getFlagCountry = (cty) => {
    if (cty) {
      if (cty == "Global Search") {
        return require("../../../../flag/world.png");
      }
      const flag = Country.filter((obj) => obj.country === cty);

      return flag[0].src;
    }

    return require("../../../../flag/world.png");
  };

  const getId = (country) => {
    if (country) {
      if (country == "Global Search") {
        return 0;
      }
      const flag = Country.filter((obj) => obj.country === country);

      return flag[0].id;
    }

    return 0;
  };

  const updateHsCodes = (array) => {
    setHsCodeSelected(array);
  };

  const isButtonAbled = () => {
    if (basedOn === "name") {
      return !!(country !== "" && companyName !== "");
    }

    if (basedOn === "hsCode") {
      return !!(role && hsCodeSelected.length > 0 && country !== "");
    }

    if (basedOn === "product") {
      return !!(role && productKeywords !== "");
    }
    if (basedOn === "ports") {
      return !!(role && portOfLading !== "");
    }
  };

  const fetchCompaniesByName =
    switchValue === "api" ? fetchCompanies : fetchCompaniesByNameDB;

  const requestCompanies =
    switchValue === "api" ? requestCompaniesAPI : RequestCompaniesDb;

  const searchData = () => {
    props.setLoading(true);
    props.setCompanyData([]);
    props.getCountry(country);

    //Buscar empresa com base no nome da empresa
    if (listType === "company" && basedOn === "name" && isButtonAbled()) {
      fetchCompaniesByName(companyName, country)
        .then((resp) => {
          props.setShipment(false);
          props.getCompanies(resp);
          props.setLoading(false);
          props.setTabs("1");
          props.setDataList(false);
        })
        .catch((e) => {
          console.log(e);
          props.setLoading(false);
        });
    }

    //Buscar empresa com base no HSCode
    if (listType === "company" && basedOn === "hsCode" && isButtonAbled()) {
      console.log("ETP1");

      if (countryPartner === "World") {
        requestCompanies(role, country, "", hsCodeSelected)
          .then((resp) => {
            props.setShipment(false);
            props.getCompanies(resp);
            props.setLoading(false);
            props.setTabs("1");
            props.setDataList(false);
            console.log("ETP2");
            console.log(resp);
          })
          .catch((e) => {
            console.log("ETP3");
            console.log(e);
            props.setLoading(false);
          });
      } else {
        requestCompanies(role, country, countryPartner, hsCodeSelected)
          .then((resp) => {
            props.setShipment(false);
            props.getCompanies(resp);
            props.setLoading(false);
            props.setTabs("1");
            props.setDataList(false);
            console.log("ETP2");
            console.log(resp);
          })
          .catch((e) => {
            console.log("ETP3");
            console.log(e);
            props.setLoading(false);
          });
      }
    }

    //Buscar shipment com base no nome da empresa
    if (listType === "shipment" && basedOn === "name") {
      props.setLoading(true);
      fetchShipments(companyName, country, role)
        .then((resp) => {
          props.setTabs("5");
          props.setShipment(true);
          setTimeout(() => {
            props.setLoading(false);
          }, 1500);
        })
        .catch((e) => {
          console.log(e);
          props.setLoading(false);
        });
    }

    //Buscar shipment com base no HsCode
    if (
      (listType === "shipment" && basedOn === "hsCode") ||
      basedOn === "product" ||
      basedOn === "ports"
    ) {
      const limit = 10;
      props.setLoading(true);
      fetchShipmentsByHs(
        hsCodeSelected,
        country,
        role,
        countryTrade,
        fromDateShip,
        toDateShip,
        productKeywords,
        portOfLading,
        portOfUnlading,
        limit
      )
        .then((resp) => {
          props.setTabs("5");
          props.setShipment(true);
          setTimeout(() => {
            props.setLoading(false);
          }, 1500);
        })
        .catch((e) => {
          console.log(e);
          props.setLoading(false);
        });
    }

    if (listType === "scout" && companyLink?.length === 0) {
      setErrorMessage("Please enter a domain.");
      props.setLoading(false);
    } else if (listType === "scout" && companyLink?.length > 0) {
      props.setLoading(false);

      try {
        props.setLoading(true);
        fetchScout().then(() => props.setLoading(false));
      } catch (error) {
        console.error("Error fetching geo companies:", error);
        message.error("Failed to fetch companies");
        props.setLoading(false);
      }
    }

    if (listType === "geolocation" && companyLink?.length === 0) {
      setErrorMessage("Please enter a domain.");
      props.setLoading(false);
    } else if (listType === "geolocation" && companyLink?.length > 0) {
      props.setLoading(false);

      try {
        props.setLoading(true);
        fetchGeoCompanies().then(() => props.setLoading(false));
      } catch (error) {
        console.error("Error fetching geo companies:", error);
        message.error("Failed to fetch companies");
        props.setLoading(false);
      }
    } else if (listType === "un") {
      if (show && periodUN && hsCodeSelected && role && countryPartner) {
        if (country) {
          props.requestUNComtrade(
            show,
            periodUN,
            getId(country),
            hsCodeSelected,
            role,
            getId(countryPartner)
          );
        } else {
          props.requestUNComtrade(
            show,
            periodUN,
            "0",
            hsCodeSelected,
            role,
            getId(countryPartner)
          );
        }
      } else {
        props.setLoading(false);
      }
    }

    // if(listType !== "geolocation") {
    //     props.getCountry(country)
    // }
  };

  const clearInputs = () => {
    setListType("");
    setBasedOn("");
    setRole("");
    setCountry("");
    setPeriod("");
    setCountryPartner("");
    setCompanyName("");
    setHsCodeSelected([]);
    setHsCodeM(true);
    setFromDateShip(null);
    setToDateShip(null);
    setIndustries([]);
    setType("");
    setCompanyLink("");
    setProductKeywords([]);
    setPortLading([]);
    setPortUnlading([]);
  };

  const companyTypes = [
    "Privately Held",
    "Public Company",
    "Partnership",
    "Self Employed",
    "Non Profit",
    "Educational",
    "Self Owned",
    "Government Agency",
  ];

  const companySize = [
    "Self-Employed",
    "2-10",
    "11-50",
    "51-100",
    "101-200",
    "201-500",
    "501-1,000",
    "1,001-5,000",
    "5,001-10,000",
    "10,001+",
  ];

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setFromDateShip(dates[0].format("YYYY-MM-DD"));
      setToDateShip(dates[1].format("YYYY-MM-DD"));
    } else {
      setFromDateShip(null);
      setToDateShip(null);
    }
  };

  const handleChangeSelect = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setIndustries(selectedValues);
  };

  const options = IndustriesMock.map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <ContainerFilterBar>
      <ColumnContainer style={{ paddingLeft: 100 }}>
        <TextDefault size="38px" color="#4b4b4b" fontWeight="800">
          Filters
        </TextDefault>
        {listType === "scout" ? (
          <TextDefault
            size="12px"
            color="#8a97aa"
            fontWeight="700"
            style={{ marginTop: 10 }}
          >
            Insert the domain website of the company you want to find suppliers,
            competitors and investors for
          </TextDefault>
        ) : (
          <TextDefault
            size="12px"
            color="#8a97aa"
            fontWeight="700"
            style={{ marginTop: 10 }}
          >
            Filter the data according to the profile of the supplier or customer
            you want
          </TextDefault>
        )}
      </ColumnContainer>

      <ColumnContainer
        style={{
          paddingLeft: 85,
          paddingRight: 20,
          marginTop: 20,
          width: "100%",
          height: "70%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <TextDefault
          size="12px"
          color="#8a97aa"
          fontWeight="700"
          style={{ marginTop: 10 }}
        >
          List Type*
        </TextDefault>
        <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
          <SelectDefault
            className={"inputSale   selectSale"}
            type="text"
            style={{ color: "#94a3b8" }}
            value={listType}
            onChange={(item) => setListType(item.target.value)}
          >
            <option value="" disabled defaultValue>
              Company or Shipment
            </option>
            <option value={"company"}>Company</option>
            <option value={"shipment"}>Shipment</option>
            <option value={"geolocation"}>Geolocation</option>
            {/* <option value={"scout"}>Supply Chain Scout</option> */}
            <option value={"un"}>UN Comtrade</option>
          </SelectDefault>
          <ImgOpt
            className="iconInputHide"
            src={require("../../../../icons/down-arrow 2.png")}
          />
        </GroupInput>
        {listType === "company" ||
        listType === "shipment" ||
        (listType !== "un" &&
          listType !== "geolocation" &&
          listType !== "scout") ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Filter based on*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <SelectDefault
                className={"inputSale   selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={basedOn}
                onChange={(item) => {
                  const selectedValue = item.target.value;
                  setBasedOn(selectedValue);

                  if (selectedValue === "hsCode") {
                    setProductKeywords([]);
                    setPortLading([]);
                    setPortUnlading([]);
                  } else if (selectedValue === "product") {
                    setHsCodeSelected([]);
                  }
                }}
              >
                <option value="" disabled selected>
                  Search by product or company name
                </option>
                <option value={"hsCode"}>HS Code</option>
                <option value={"name"}>Company Name</option>
                {listType === "shipment" ? (
                  <>
                    <option value={"product"}>Product Name</option>
                    <option value={"ports"}>Ports</option>
                  </>
                ) : (
                  ""
                )}
              </SelectDefault>

              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
          </>
        ) : null}

        {basedOn === "hsCode" ||
        basedOn === "name" ||
        basedOn === "ports" ||
        (basedOn === "product" &&
          listType !== "geolocation" &&
          listType !== "scout") ||
        listType === "un" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Role*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <SelectDefault
                className={"inputSale   selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={role}
                onChange={(item) => setRole(item.target.value)}
              >
                <option value="" disabled defaultValue>
                  Role
                </option>
                <option value={"Supplier"}>Supplier | Export</option>
                <option value={"Buyer"}>Buyer | Import</option>
              </SelectDefault>
              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}
        {listType === "un" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Show*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <SelectDefault
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={show}
                onChange={(item) => setShow(item.target.value)}
              >
                <option value="" disabled defaultValue>
                  Show
                </option>
                <option value={"A"}>Annual</option>
                <option value={"M"}>Monthly</option>
              </SelectDefault>
              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Period*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <SelectDefault
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={periodUN}
                onChange={(item) => setPeriodUN(item.target.value)}
              >
                <option value="" disabled defaultValue>
                  Period
                </option>
                {show !== "M" ? (
                  <>
                    <option value={"2023"}>2023</option>
                    <option value={"2022"}>2022</option>
                    <option value={"2021"}>2021</option>
                    <option value={"2020"}>2020</option>
                    <option value={"2019"}>2019</option>
                    <option value={"2018"}>2018</option>
                    <option value={"2017"}>2017</option>
                    <option value={"2016"}>2016</option>
                    <option value={"2015"}>2015</option>
                  </>
                ) : (
                  <>
                    <option value={"202401"}>202401</option>
                    <option value={"202402"}>202402</option>
                    <option value={"202403"}>202403</option>
                    <option value={"202404"}>202404</option>
                    <option value={"202405"}>202405</option>
                    <option value={"202406"}>202406</option>
                    <option value={"202301"}>202301</option>
                    <option value={"202302"}>202302</option>
                    <option value={"202303"}>202303</option>
                    <option value={"202304"}>202304</option>
                    <option value={"202305"}>202305</option>
                    <option value={"202306"}>202306</option>
                    <option value={"202307"}>202307</option>
                    <option value={"202308"}>202308</option>
                    <option value={"202309"}>202309</option>
                    <option value={"202310"}>202310</option>
                    <option value={"202311"}>202311</option>
                    <option value={"202312"}>202312</option>
                  </>
                )}
              </SelectDefault>
              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}

        {}
        {listType === "scout" ? (
          ""
        ) : (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Country*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <ImgOpt
                className="iconInputSale"
                src={getFlagCountry(country)}
                style={{ width: 35, height: 35, opacity: 0.8 }}
              />
              <SelectDefault
                className={"inputSale   selectSale"}
                type="text"
                style={{ color: "#94a3b8", paddingLeft: 60 }}
                value={country}
                onChange={(item) => setCountry(item.target.value)}
              >
                {listType === "un" ? (
                  <option value="World" defaultValue>
                    World
                  </option>
                ) : (
                  <option value="" disabled defaultValue>
                    Country
                  </option>
                )}

                {Country.map((item, index) => (
                  <option value={item.country} key={index}>
                    {item.country}
                  </option>
                ))}
              </SelectDefault>
              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
          </>
        )}

        {listType === "geolocation" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Domain*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                id="domainInput"
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                placeholder="www.example.com"
                onChange={(e) => setCompanyLink(e.target.value)}
                required
              />
            </GroupInput>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Type
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <SelectDefault
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="" disabled defaultValue>
                  Type
                </option>
                {companyTypes.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </SelectDefault>
              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
            {/* <TextDefault size="12px" color="#8a97aa" fontWeight="700" style={{marginTop: 25}}>Size</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <SelectDefault                
                                className={"inputSale selectSale"}
                                type="text"
                                style={{ color: "#94a3b8" }}
                                value={size}
                                onChange={(item) => setSize(item.target.value.split(" ")[0])}
                            >
                                <option value="" disabled defaultValue>
                                    Size
                                </option>
                                {companySize.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}               
                            </SelectDefault>
                            <ImgOpt className="iconInputHide" src={require("../../../../icons/down-arrow 2.png")}/>
                        </GroupInput> */}
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Industries
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <Select
                options={options}
                isMulti
                styles={customStyles}
                // className="inputSale selectSale"
                placeholder="Industries"
                value={options.filter((option) =>
                  industries.includes(option.value)
                )}
                onChange={handleChangeSelect}
              />
            </GroupInput>

            {/* <TextDefault size="12px" color="#8a97aa" fontWeight="700" style={{marginTop: 25}}>States</TextDefault>
                        <GroupInput className="groupInputSale" style={{marginTop: 5}}>
                            <InputDefault                
                                className={"inputSale selectSale"}
                                type="text"
                                style={{ color: "#94a3b8" }}
                                 values={states}
                                 onChange={(item) => setStates(item.target.value)}
                            />
                        </GroupInput> */}
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Key words to include
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={keyWordsIncluded.join(", ")}
                onChange={(e) => {
                  setKeyWordsIncluded(
                    e.target.value.split(",").map((item) => item.trim())
                  );
                }}
              />
            </GroupInput>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Key words to exclude
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={keyWordsExcluded.join(", ")}
                onChange={(e) => {
                  setKeyWordsExcluded(
                    e.target.value.split(",").map((item) => item.trim())
                  );
                }}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}

        {listType === "scout" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Domain
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                id="domainInput"
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                placeholder="www.example.com"
                onChange={(e) => setCompanyLink(e.target.value)}
                required
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}

        {listType !== "shipment" &&
        listType !== "geolocation" &&
        listType !== "scout" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Country trade partner
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <ImgOpt
                className="iconInputSale"
                src={getFlagCountry(countryPartner)}
                style={{ width: 35, height: 35, opacity: 0.8 }}
              />
              <SelectDefault
                className={"inputSale selectSale"}
                type="text"
                style={{ color: "#94a3b8", paddingLeft: 60 }}
                value={countryPartner}
                onChange={(item) => setCountryPartner(item.target.value)}
              >
                {listType === "un" ? (
                  <option value="World" defaultValue>
                    World
                  </option>
                ) : (
                  <option value="" disabled defaultValue>
                    Country Partner
                  </option>
                )}
                {listType !== "Shipment"
                  ? Country.map((item, index) => (
                      <option value={item.country} key={index}>
                        {item.country}
                      </option>
                    ))
                  : CountryShip.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
              </SelectDefault>
              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}

        {listType === "shipment" && basedOn !== "name" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Period
            </TextDefault>
            <Space direction="vertical" size={12}>
              <RangePicker onChange={handleDateChange} />
            </Space>

            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Country Trader
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              {/* <ImgOpt className="iconInputSale" src={getFlagCountry(countryTrade)} style={{width: 35, height: 35, opacity: 0.8}} /> */}
              <SelectDefault
                className={"inputSale   selectSale"}
                type="text"
                style={{ color: "#94a3b8" }}
                value={countryTrade}
                onChange={(item) => setCountryTrade(item.target.value)}
              >
                <option value="" disabled defaultValue>
                  Country Tarder
                </option>

                {Country.map((item, index) => (
                  <option value={item.country} key={index}>
                    {item.country}
                  </option>
                ))}
              </SelectDefault>
              <ImgOpt
                className="iconInputHide"
                src={require("../../../../icons/down-arrow 2.png")}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}

        {listType === "un" ||
        (listType !== "geolocation" &&
          listType !== "scout" &&
          basedOn !== "name" &&
          basedOn !== "product") ? (
          <>
            <HsCodeSelected
              data={hsCode}
              getHsCode={updateHsCodes}
              hsCodeM={hsCodeM}
              setHsCodeM={setHsCodeM}
              listType={listType}
            />
          </>
        ) : null}

        {listType !== "geolocation" &&
        listType !== "scout" &&
        listType !== "un" &&
        basedOn === "name" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Company Name*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                className={"inputSale   selectSale"}
                placeholder="Company Name"
                type="text"
                style={{ color: "#94a3b8" }}
                value={companyName}
                onChange={(item) => setCompanyName(item.target.value)}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}

        {listType !== "geolocation" &&
        listType !== "scout" &&
        listType !== "un" &&
        basedOn === "product" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              fontWeight="700"
              style={{ marginTop: 25 }}
            >
              Product Name*
              <Tooltip title="Use a comma to add more products">
                <IconButton>
                  <InfoIcon sx={{ fontSize: "1.2rem", color: "#8a97aa" }} />
                </IconButton>
              </Tooltip>
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                className={"inputSale   selectSale"}
                placeholder="Product Name"
                type="text"
                style={{ color: "#94a3b8" }}
                value={productKeywords.join(", ")}
                onChange={(e) => {
                  setProductKeywords(
                    e.target.value.split(",").map((item) => item.trim())
                  );
                }}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}

        {listType !== "geolocation" &&
        listType !== "scout" &&
        listType !== "un" &&
        listType === "shipment" &&
        basedOn === "ports" ? (
          <>
            <PortAirportSelected
              data={test}
              listType={listType}
              portOfLading={portOfLading}
              setPortLading={setPortLading}
            />

            <PortAirportUnladingSelected
              data={test}
              listType={listType}
              portOfLading={portOfUnlading}
              setPortLading={setPortUnlading}
            />
          </>
        ) : (
          ""
        )}
      </ColumnContainer>

      <RowContainer
        style={{
          width: "100%",
          paddingLeft: 85,
          paddingTop: 10,
          position: "absolute",
          bottom: 20,
          boxShadow: "0px -20px 35px rgba(0, 0, 0, 0.05)",
        }}
      >
        <BtnClearFilter onClick={clearInputs}>
          <ImgDefault
            src={require("../../../../icons/clean 1.png")}
            width="30px"
            height="30px"
          />
        </BtnClearFilter>

        <BtnSFilter onClick={() => searchData()}>
          {!props.getLoading ? (
            <TextDefault size="25px" color="#fff" fontWeight="500">
              Search
            </TextDefault>
          ) : (
            <div
              aria-label="Loading..."
              role="status"
              className="loaderGet"
              style={{ width: 75 }}
            >
              <svg className="iconLoading" viewBox="0 0 256 256">
                <line
                  x1="128"
                  y1="32"
                  x2="128"
                  y2="64"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="195.9"
                  y1="60.1"
                  x2="173.3"
                  y2="82.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="224"
                  y1="128"
                  x2="192"
                  y2="128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="195.9"
                  y1="195.9"
                  x2="173.3"
                  y2="173.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="128"
                  y1="224"
                  x2="128"
                  y2="192"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="60.1"
                  y1="195.9"
                  x2="82.7"
                  y2="173.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="32"
                  y1="128"
                  x2="64"
                  y2="128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="60.1"
                  y1="60.1"
                  x2="82.7"
                  y2="82.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
              </svg>
              <TextDefault size="13px" color="#fff" fontWeight="700">
                Loading...
              </TextDefault>
            </div>
          )}
        </BtnSFilter>
      </RowContainer>
    </ContainerFilterBar>
  );
}
